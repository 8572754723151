<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 lg10 xl10 pt-4 align-self-center text-center>
        <span class="popsemibold" v-if="orderData.length > 0"> MY ORDERS </span>
        <v-layout wrap justify-center py-4>
          <v-flex xs12 md12 align-self-center>
            <v-layout
              wrap
              justify-center
              v-for="(item, i) in orderData"
              :key="i"
            >
              <v-flex xs12 md12 align-self-center>
                <OrderDetails v-bind:storage="item" @stepper="winStepper" />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center fill-height v-if="orderData.length < 1">
      <v-flex xs12 text-center align-self-center>
        <span style="color: #000" class="popregular"
          >Your have placed no orders!</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import OrderDetails from "./orderDetails";
export default {
  components: {
    OrderDetails,
  },
  data() {
    return {
      appLoading: false,
      totalAmount: null,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      orderData: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(windowData) {
      this.showSnackBar = true;
      this.msg = windowData.pageResponse;
      // this.msg = windowData.pageResponse.msg;
      this.getData();
    },
    winStepper2(windowData) {
      if (windowData.getData) this.getData();
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/myorders",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: { update: true },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.orderData = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
    //   return strTime;
    // },

    formatDate(item) {
      // var dt = new Date('2014-8-20');
      var dt = new Date(item);
      // var a=dt.toISOString()
      // console.log("aa",a)
      // console.log("init",dt)
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toUTCString();
      var strTime = dt.slice(4, 11) + " " + year + " ";
      // var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      // console.log("dd",dt)
      // console.log("bef4",item)
      return strTime;
    },
  },
};
</script>
